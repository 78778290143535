<template>
  <ul class="g2">
    <li class="card">
      <div><!----><!---->
        <div class="card-body"><!----><!---->
          <div>
            <div class="info-box">
              <div media=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class=""><img
                    src="https://images.guiafacil.com/logomarcas/carla-mudancas-30822.jpg" alt="Image 1"
                    class="imgSize"></a></div>
              <div content=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class="">
                  <div class="m-0 tituloCard d-lg-block d-md-block d-block"><span>
                      Carla Fretes e Mudanças
                    </span> <img src="@/assets/verify.png" width="16" heigth="16" alt=""/></div>
                </a>
                <p address=""><a href="/encontre/mudancas/balneario-camboriu/sc/" target="_blank" class=""><small>
                      Mudanças </small></a> <a href="/encontre/mudancas/balneario-camboriu/sc/bairro/" target="_blank"
                    class=""><small>| ., </small> <small> Balneário Camboriú
                    </small></a></p>
                <div>
                  <div class="d-inline"><small class="rating-text">5</small> <output id="rating-inline" dir="ltr"
                      tabindex="0" role="slider" aria-readonly="true" aria-live="off" aria-valuemin="1" aria-valuemax="5"
                      aria-valuenow="5"
                      class="b-rating form-control align-items-center d-inline-flex readonly form-control-sm"><span
                        class="b-rating-star flex-grow-1 b-rating-star-full"><span class="b-rating-icon"><svg
                            viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                            aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span></output> <small class="rating-text"> </small></div>
                  <!----> <!---->
                </div>
                <p class="mb-0 descricaoCard">
                  Realizamos mudanças residenciais, comerciais com montagem e desmontagem de móveis e embalagens em geral.
                  Com equipe unif...
                  <span class="openModal">Ver mais</span>

                </p>
                <p>
                  R. Sebastião Fabrício, 31 - Jardim Santa Rosa, Campinas - SP, 13058-743
                </p>
              </div>
            </div>
            <div class="g">
              <div class="buttons-margin">
                <div class="bottom-whatsapp">
                  <div><button type="button" class="btn button-whats-default btn-link"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                      <p class="text-white">WhatsApp</p>
                    </button> <!----> <!----></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="tel:19993537939"><button type="button"
                        class="btn button-default d-block d-md-block d-lg-none btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <p>Ligar</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a
                      href="https://www.google.com/maps/place/Rua Jose Dutra de Carvalho, 267 Jardim Maria Helena , Campinas, SP"><button
                        type="button" class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p class="d-block d-md-block d-lg-none">Rotas</p>
                      </button></a></div> <!---->
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="https://www.procurocarreto.com.br/"><button type="button"
                        class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                          <path
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        <p class="inner-text">Site</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><button type="button" class="btn button-default btn-link" v-on:click="share"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <p class="inner-text">Compartilhar</p>
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div><!----><!---->
      </div>
    </li>
    <li class="card">
      <div><!----><!---->
        <div class="card-body"><!----><!---->
          <div>
            <div class="info-box">
              <div media=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class=""><img
                    src="https://images.guiafacil.com/logomarcas/carla-mudancas-30822.jpg" alt="Image 1"
                    class="imgSize"></a></div>
              <div content=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class="">
                  <div class="m-0 tituloCard d-lg-block d-md-block d-block"><span>
                      Carla Fretes e Mudanças
                    </span> <img src="@/assets/verify.png" width="16" heigth="16" alt=""/>
                   </div>
                </a>
                <p address=""><a href="/encontre/mudancas/balneario-camboriu/sc/" target="_blank" class=""><small>
                      Mudanças </small></a> <a href="/encontre/mudancas/balneario-camboriu/sc/bairro/" target="_blank"
                    class=""><small>| ., </small> <small> Balneário Camboriú
                    </small></a></p>
                <div>
                  <div class="d-inline"><small class="rating-text">5</small> <output id="rating-inline" dir="ltr"
                      tabindex="0" role="slider" aria-readonly="true" aria-live="off" aria-valuemin="1" aria-valuemax="5"
                      aria-valuenow="5"
                      class="b-rating form-control align-items-center d-inline-flex readonly form-control-sm"><span
                        class="b-rating-star flex-grow-1 b-rating-star-full"><span class="b-rating-icon"><svg
                            viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                            aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span></output> <small class="rating-text"> </small></div>
                  <!----> <!---->
                </div>
                <p class="mb-0 descricaoCard">
                  Realizamos mudanças residenciais, comerciais com montagem e desmontagem de móveis e embalagens em geral.
                  Com equipe unif...
                  <span class="openModal">Ver mais</span>
                </p>
              </div>
            </div>
            <div class="g">
              <div class="buttons-margin">
                <div class="bottom-whatsapp">
                    <button type="button" class="btn button-whats-default btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-whatsapp" viewBox="0 0 16 16">
                          <path
                            d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                        </svg>
                        <p class="text-white">WhatsApp</p>
                      </button>
                  </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="tel:19993537939"><button type="button"
                        class="btn button-default d-block d-md-block d-lg-none btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <p>Ligar</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a
                      href="https://www.google.com/maps/place/Rua Jose Dutra de Carvalho, 267 Jardim Maria Helena , Campinas, SP"><button
                        type="button" class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p class="d-block d-md-block d-lg-none">Rotas</p>
                      </button></a></div> <!---->
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="https://www.procurocarreto.com.br/"><button type="button"
                        class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                          <path
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        <p class="inner-text">Site</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><button type="button" class="btn button-default btn-link" v-on:click="share"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <p class="inner-text">Compartilhar</p>
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div><!----><!---->
      </div>
    </li>
    <li class="card">
      <div><!----><!---->
        <div class="card-body"><!----><!---->
          <div>
            <div class="info-box">
              <div media=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class=""><img
                    src="https://images.guiafacil.com/logomarcas/carla-mudancas-30822.jpg" alt="Image 1"
                    class="imgSize"></a></div>
              <div content=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class="">
                  <div class="m-0 tituloCard d-lg-block d-md-block d-block"><span>
                      Carla Fretes e Mudanças
                    </span> <img src="@/assets/verify.png" width="16" heigth="16" alt=""/></div>
                </a>
                <p address=""><a href="/encontre/mudancas/balneario-camboriu/sc/" target="_blank" class=""><small>
                      Mudanças </small></a> <a href="/encontre/mudancas/balneario-camboriu/sc/bairro/" target="_blank"
                    class=""><small>| ., </small> <small> Balneário Camboriú
                    </small></a></p>
                <div>
                  <div class="d-inline"><small class="rating-text">5</small> <output id="rating-inline" dir="ltr"
                      tabindex="0" role="slider" aria-readonly="true" aria-live="off" aria-valuemin="1" aria-valuemax="5"
                      aria-valuenow="5"
                      class="b-rating form-control align-items-center d-inline-flex readonly form-control-sm"><span
                        class="b-rating-star flex-grow-1 b-rating-star-full"><span class="b-rating-icon"><svg
                            viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                            aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span></output> <small class="rating-text"> </small></div>
                  <!----> <!---->
                </div>
                <p class="mb-0 descricaoCard">
                  Realizamos mudanças residenciais, comerciais com montagem e desmontagem de móveis e embalagens em geral.
                  Com equipe unif...
                  <span class="openModal">Ver mais</span>
                </p>
              </div>
            </div>
            <div class="g">
              <div class="buttons-margin">
                <div class="bottom-whatsapp">
                  <div><button type="button" class="btn button-whats-default btn-link"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                      <p class="text-white">WhatsApp</p>
                    </button> <!----> <!----></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="tel:19993537939"><button type="button"
                        class="btn button-default d-block d-md-block d-lg-none btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <p>Ligar</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a
                      href="https://www.google.com/maps/place/Rua Jose Dutra de Carvalho, 267 Jardim Maria Helena , Campinas, SP"><button
                        type="button" class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p class="d-block d-md-block d-lg-none">Rotas</p>
                      </button></a></div> <!---->
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="https://www.procurocarreto.com.br/"><button type="button"
                        class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                          <path
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        <p class="inner-text">Site</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><button type="button" class="btn button-default btn-link" v-on:click="share"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <p class="inner-text">Compartilhar</p>
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div><!----><!---->
      </div>
    </li>
    <li class="card">
      <div><!----><!---->
        <div class="card-body"><!----><!---->
          <div>
            <div class="info-box">
              <div media=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class=""><img
                    src="https://images.guiafacil.com/logomarcas/carla-mudancas-30822.jpg" alt="Image 1"
                    class="imgSize"></a></div>
              <div content=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class="">
                  <div class="m-0 tituloCard d-lg-block d-md-block d-block"><span>
                      Carla Fretes e Mudanças
                    </span> <img src="@/assets/verify.png" width="16" heigth="16" alt=""/></div>
                </a>
                <p address=""><a href="/encontre/mudancas/balneario-camboriu/sc/" target="_blank" class=""><small>
                      Mudanças </small></a> <a href="/encontre/mudancas/balneario-camboriu/sc/bairro/" target="_blank"
                    class=""><small>| ., </small> <small> Balneário Camboriú
                    </small></a></p>
                <div>
                  <div class="d-inline"><small class="rating-text">5</small> <output id="rating-inline" dir="ltr"
                      tabindex="0" role="slider" aria-readonly="true" aria-live="off" aria-valuemin="1" aria-valuemax="5"
                      aria-valuenow="5"
                      class="b-rating form-control align-items-center d-inline-flex readonly form-control-sm"><span
                        class="b-rating-star flex-grow-1 b-rating-star-full"><span class="b-rating-icon"><svg
                            viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                            aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span></output> <small class="rating-text"> </small></div>
                  <!----> <!---->
                </div>
                <p class="mb-0 descricaoCard">
                  Realizamos mudanças residenciais, comerciais com montagem e desmontagem de móveis e embalagens em geral.
                  Com equipe unif...
                  <span class="openModal">Ver mais</span>
                </p>
              </div>
            </div>
            <div class="g">
              <div class="buttons-margin">
                <div class="bottom-whatsapp">
                  <div><button type="button" class="btn button-whats-default btn-link"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                      <p class="text-white">WhatsApp</p>
                    </button> <!----> <!----></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="tel:19993537939"><button type="button"
                        class="btn button-default d-block d-md-block d-lg-none btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <p>Ligar</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a
                      href="https://www.google.com/maps/place/Rua Jose Dutra de Carvalho, 267 Jardim Maria Helena , Campinas, SP"><button
                        type="button" class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p class="d-block d-md-block d-lg-none">Rotas</p>
                      </button></a></div> <!---->
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="https://www.procurocarreto.com.br/"><button type="button"
                        class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                          <path
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        <p class="inner-text">Site</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><button type="button" class="btn button-default btn-link" v-on:click="share"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <p class="inner-text">Compartilhar</p>
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div><!----><!---->
      </div>
    </li>
    <li class="card">
      <div><!----><!---->
        <div class="card-body"><!----><!---->
          <div>
            <div class="info-box">
              <div media=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class=""><img
                    src="https://images.guiafacil.com/logomarcas/carla-mudancas-30822.jpg" alt="Image 1"
                    class="imgSize"></a></div>
              <div content=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class="">
                  <div class="m-0 tituloCard d-lg-block d-md-block d-block"><span>
                      Carla Fretes e Mudanças
                    </span> <img src="@/assets/verify.png" width="16" heigth="16" alt=""/></div>
                </a>
                <p address=""><a href="/encontre/mudancas/balneario-camboriu/sc/" target="_blank" class=""><small>
                      Mudanças </small></a> <a href="/encontre/mudancas/balneario-camboriu/sc/bairro/" target="_blank"
                    class=""><small>| ., </small> <small> Balneário Camboriú
                    </small></a></p>
                <div>
                  <div class="d-inline"><small class="rating-text">5</small> <output id="rating-inline" dir="ltr"
                      tabindex="0" role="slider" aria-readonly="true" aria-live="off" aria-valuemin="1" aria-valuemax="5"
                      aria-valuenow="5"
                      class="b-rating form-control align-items-center d-inline-flex readonly form-control-sm"><span
                        class="b-rating-star flex-grow-1 b-rating-star-full"><span class="b-rating-icon"><svg
                            viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                            aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span></output> <small class="rating-text"> </small></div>
                  <!----> <!---->
                </div>
                <p class="mb-0 descricaoCard">
                  Realizamos mudanças residenciais, comerciais com montagem e desmontagem de móveis e embalagens em geral.
                  Com equipe unif...
                  <span class="openModal">Ver mais</span>
                </p>
              </div>
            </div>
            <div class="g">
              <div class="buttons-margin">
                <div class="bottom-whatsapp">
                  <div><button type="button" class="btn button-whats-default btn-link"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                      <p class="text-white">WhatsApp</p>
                    </button> <!----> <!----></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="tel:19993537939"><button type="button"
                        class="btn button-default d-block d-md-block d-lg-none btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <p>Ligar</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a
                      href="https://www.google.com/maps/place/Rua Jose Dutra de Carvalho, 267 Jardim Maria Helena , Campinas, SP"><button
                        type="button" class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p class="d-block d-md-block d-lg-none">Rotas</p>
                      </button></a></div> <!---->
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="https://www.procurocarreto.com.br/"><button type="button"
                        class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                          <path
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        <p class="inner-text">Site</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><button type="button" class="btn button-default btn-link" v-on:click="share"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <p class="inner-text">Compartilhar</p>
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div><!----><!---->
      </div>
    </li>
    <li class="card">
      <div><!----><!---->
        <div class="card-body"><!----><!---->
          <div>
            <div class="info-box">
              <div media=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class=""><img
                    src="https://images.guiafacil.com/logomarcas/carla-mudancas-30822.jpg" alt="Image 1"
                    class="imgSize"></a></div>
              <div content=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class="">
                  <div class="m-0 tituloCard d-lg-block d-md-block d-block"><span>
                      Carla Fretes e Mudanças
                    </span> <img src="@/assets/verify.png" width="16" heigth="16" alt=""/></div>
                </a>
                <p address=""><a href="/encontre/mudancas/balneario-camboriu/sc/" target="_blank" class=""><small>
                      Mudanças </small></a> <a href="/encontre/mudancas/balneario-camboriu/sc/bairro/" target="_blank"
                    class=""><small>| ., </small> <small> Balneário Camboriú
                    </small></a></p>
                <div>
                  <div class="d-inline"><small class="rating-text">5</small> <output id="rating-inline" dir="ltr"
                      tabindex="0" role="slider" aria-readonly="true" aria-live="off" aria-valuemin="1" aria-valuemax="5"
                      aria-valuenow="5"
                      class="b-rating form-control align-items-center d-inline-flex readonly form-control-sm"><span
                        class="b-rating-star flex-grow-1 b-rating-star-full"><span class="b-rating-icon"><svg
                            viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                            aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span></output> <small class="rating-text"> </small></div>
                  <!----> <!---->
                </div>
                <p class="mb-0 descricaoCard">
                  Realizamos mudanças residenciais, comerciais com montagem e desmontagem de móveis e embalagens em geral.
                  Com equipe unif...
                  <span class="openModal">Ver mais</span>
                </p>
              </div>
            </div>
            <div class="g">
              <div class="buttons-margin">
                <div class="bottom-whatsapp">
                  <div><button type="button" class="btn button-whats-default btn-link"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                      <p class="text-white">WhatsApp</p>
                    </button> <!----> <!----></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="tel:19993537939"><button type="button"
                        class="btn button-default d-block d-md-block d-lg-none btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <p>Ligar</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a
                      href="https://www.google.com/maps/place/Rua Jose Dutra de Carvalho, 267 Jardim Maria Helena , Campinas, SP"><button
                        type="button" class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p class="d-block d-md-block d-lg-none">Rotas</p>
                      </button></a></div> <!---->
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="https://www.procurocarreto.com.br/"><button type="button"
                        class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                          <path
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        <p class="inner-text">Site</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><button type="button" class="btn button-default btn-link" v-on:click="share"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <p class="inner-text">Compartilhar</p>
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div><!----><!---->
      </div>
    </li>
    <li class="card">
      <div><!----><!---->
        <div class="card-body"><!----><!---->
          <div>
            <div class="info-box">
              <div media=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class=""><img
                    src="https://images.guiafacil.com/logomarcas/carla-mudancas-30822.jpg" alt="Image 1"
                    class="imgSize"></a></div>
              <div content=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class="">
                  <div class="m-0 tituloCard d-lg-block d-md-block d-block"><span>
                      Carla Fretes e Mudanças
                    </span> <img src="@/assets/verify.png" width="16" heigth="16" alt=""/></div>
                </a>
                <p address=""><a href="/encontre/mudancas/balneario-camboriu/sc/" target="_blank" class=""><small>
                      Mudanças </small></a> <a href="/encontre/mudancas/balneario-camboriu/sc/bairro/" target="_blank"
                    class=""><small>| ., </small> <small> Balneário Camboriú
                    </small></a></p>
                <div>
                  <div class="d-inline"><small class="rating-text">5</small> <output id="rating-inline" dir="ltr"
                      tabindex="0" role="slider" aria-readonly="true" aria-live="off" aria-valuemin="1" aria-valuemax="5"
                      aria-valuenow="5"
                      class="b-rating form-control align-items-center d-inline-flex readonly form-control-sm"><span
                        class="b-rating-star flex-grow-1 b-rating-star-full"><span class="b-rating-icon"><svg
                            viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                            aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span></output> <small class="rating-text"> </small></div>
                  <!----> <!---->
                </div>
                <p class="mb-0 descricaoCard">
                  Realizamos mudanças residenciais, comerciais com montagem e desmontagem de móveis e embalagens em geral.
                  Com equipe unif...
                  <span class="openModal">Ver mais</span>
                </p>
              </div>
            </div>
            <div class="g">
              <div class="buttons-margin">
                <div class="bottom-whatsapp">
                  <div><button type="button" class="btn button-whats-default btn-link"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                      <p class="text-white">WhatsApp</p>
                    </button> <!----> <!----></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="tel:19993537939"><button type="button"
                        class="btn button-default d-block d-md-block d-lg-none btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <p>Ligar</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a
                      href="https://www.google.com/maps/place/Rua Jose Dutra de Carvalho, 267 Jardim Maria Helena , Campinas, SP"><button
                        type="button" class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p class="d-block d-md-block d-lg-none">Rotas</p>
                      </button></a></div> <!---->
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="https://www.procurocarreto.com.br/"><button type="button"
                        class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                          <path
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        <p class="inner-text">Site</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><button type="button" class="btn button-default btn-link" v-on:click="share"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <p class="inner-text">Compartilhar</p>
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div><!----><!---->
      </div>
    </li>
    <li class="card">
      <div><!----><!---->
        <div class="card-body"><!----><!---->
          <div>
            <div class="info-box">
              <div media=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class=""><img
                    src="https://images.guiafacil.com/logomarcas/carla-mudancas-30822.jpg" alt="Image 1"
                    class="imgSize"></a></div>
              <div content=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class="">
                  <div class="m-0 tituloCard d-lg-block d-md-block d-block"><span>
                      Carla Fretes e Mudanças
                    </span> <img src="@/assets/verify.png" width="16" heigth="16" alt=""/></div>
                </a>
                <p address=""><a href="/encontre/mudancas/balneario-camboriu/sc/" target="_blank" class=""><small>
                      Mudanças </small></a> <a href="/encontre/mudancas/balneario-camboriu/sc/bairro/" target="_blank"
                    class=""><small>| ., </small> <small> Balneário Camboriú
                    </small></a></p>
                <div>
                  <div class="d-inline"><small class="rating-text">5</small> <output id="rating-inline" dir="ltr"
                      tabindex="0" role="slider" aria-readonly="true" aria-live="off" aria-valuemin="1" aria-valuemax="5"
                      aria-valuenow="5"
                      class="b-rating form-control align-items-center d-inline-flex readonly form-control-sm"><span
                        class="b-rating-star flex-grow-1 b-rating-star-full"><span class="b-rating-icon"><svg
                            viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                            aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span></output> <small class="rating-text"> </small></div>
                  <!----> <!---->
                </div>
                <p class="mb-0 descricaoCard">
                  Realizamos mudanças residenciais, comerciais com montagem e desmontagem de móveis e embalagens em geral.
                  Com equipe unif...
                  <span class="openModal">Ver mais</span>
                </p>
              </div>
            </div>
            <div class="g">
              <div class="buttons-margin">
                <div class="bottom-whatsapp">
                  <div><button type="button" class="btn button-whats-default btn-link"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                      <p class="text-white">WhatsApp</p>
                    </button> <!----> <!----></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="tel:19993537939"><button type="button"
                        class="btn button-default d-block d-md-block d-lg-none btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <p>Ligar</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a
                      href="https://www.google.com/maps/place/Rua Jose Dutra de Carvalho, 267 Jardim Maria Helena , Campinas, SP"><button
                        type="button" class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p class="d-block d-md-block d-lg-none">Rotas</p>
                      </button></a></div> <!---->
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="https://www.procurocarreto.com.br/"><button type="button"
                        class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                          <path
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        <p class="inner-text">Site</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><button type="button" class="btn button-default btn-link" v-on:click="share"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <p class="inner-text">Compartilhar</p>
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div><!----><!---->
      </div>
    </li>
    <li class="card">
      <div><!----><!---->
        <div class="card-body"><!----><!---->
          <div>
            <div class="info-box">
              <div media=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class=""><img
                    src="https://images.guiafacil.com/logomarcas/carla-mudancas-30822.jpg" alt="Image 1"
                    class="imgSize"></a></div>
              <div content=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class="">
                  <div class="m-0 tituloCard d-lg-block d-md-block d-block"><span>
                      Carla Fretes e Mudanças
                    </span> <img src="@/assets/verify.png" width="16" heigth="16" alt=""/></div>
                </a>
                <p address=""><a href="/encontre/mudancas/balneario-camboriu/sc/" target="_blank" class=""><small>
                      Mudanças </small></a> <a href="/encontre/mudancas/balneario-camboriu/sc/bairro/" target="_blank"
                    class=""><small>| ., </small> <small> Balneário Camboriú
                    </small></a></p>
                <div>
                  <div class="d-inline"><small class="rating-text">5</small> <output id="rating-inline" dir="ltr"
                      tabindex="0" role="slider" aria-readonly="true" aria-live="off" aria-valuemin="1" aria-valuemax="5"
                      aria-valuenow="5"
                      class="b-rating form-control align-items-center d-inline-flex readonly form-control-sm"><span
                        class="b-rating-star flex-grow-1 b-rating-star-full"><span class="b-rating-icon"><svg
                            viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                            aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span></output> <small class="rating-text"> </small></div>
                  <!----> <!---->
                </div>
                <p class="mb-0 descricaoCard">
                  Realizamos mudanças residenciais, comerciais com montagem e desmontagem de móveis e embalagens em geral.
                  Com equipe unif...
                  <span class="openModal">Ver mais</span>
                </p>
              </div>
            </div>
            <div class="g">
              <div class="buttons-margin">
                <div class="bottom-whatsapp">
                  <div><button type="button" class="btn button-whats-default btn-link"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                      <p class="text-white">WhatsApp</p>
                    </button> <!----> <!----></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="tel:19993537939"><button type="button"
                        class="btn button-default d-block d-md-block d-lg-none btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <p>Ligar</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a
                      href="https://www.google.com/maps/place/Rua Jose Dutra de Carvalho, 267 Jardim Maria Helena , Campinas, SP"><button
                        type="button" class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p class="d-block d-md-block d-lg-none">Rotas</p>
                      </button></a></div> <!---->
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="https://www.procurocarreto.com.br/"><button type="button"
                        class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                          <path
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        <p class="inner-text">Site</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><button type="button" class="btn button-default btn-link" v-on:click="share"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <p class="inner-text">Compartilhar</p>
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div><!----><!---->
      </div>
    </li>
    <li class="card">
      <div><!----><!---->
        <div class="card-body"><!----><!---->
          <div>
            <div class="info-box">
              <div media=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class=""><img
                    src="https://images.guiafacil.com/logomarcas/carla-mudancas-30822.jpg" alt="Image 1"
                    class="imgSize"></a></div>
              <div content=""><a href="/site/carla-mudancas/navegantes/sc/73003/" target="_blank" class="">
                  <div class="m-0 tituloCard d-lg-block d-md-block d-block"><span>
                      Carla Fretes e Mudanças
                    </span> <img src="@/assets/verify.png" width="16" heigth="16" alt=""/></div>
                </a>
                <p address=""><a href="/encontre/mudancas/balneario-camboriu/sc/" target="_blank" class=""><small>
                      Mudanças </small></a> <a href="/encontre/mudancas/balneario-camboriu/sc/bairro/" target="_blank"
                    class=""><small>| ., </small> <small> Balneário Camboriú
                    </small></a></p>
                <div>
                  <div class="d-inline"><small class="rating-text">5</small> <output id="rating-inline" dir="ltr"
                      tabindex="0" role="slider" aria-readonly="true" aria-live="off" aria-valuemin="1" aria-valuemax="5"
                      aria-valuenow="5"
                      class="b-rating form-control align-items-center d-inline-flex readonly form-control-sm"><span
                        class="b-rating-star flex-grow-1 b-rating-star-full"><span class="b-rating-icon"><svg
                            viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                            aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span><span class="b-rating-star flex-grow-1 b-rating-star-full"><span
                          class="b-rating-icon"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false"
                            role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi-star-fill b-icon bi text-warning">
                            <g>
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </g>
                          </svg></span></span></output> <small class="rating-text"> </small></div>
                  <!----> <!---->
                </div>
                <p class="mb-0 descricaoCard">
                  Realizamos mudanças residenciais, comerciais com montagem e desmontagem de móveis e embalagens em geral.
                  Com equipe unif...
                  <span class="openModal">Ver mais</span>
                </p>
              </div>
            </div>
            <div class="g">
              <div class="buttons-margin">
                <div class="bottom-whatsapp">
                  <div><button type="button" class="btn button-whats-default btn-link"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                      <p class="text-white">WhatsApp</p>
                    </button> <!----> <!----></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="tel:19993537939"><button type="button"
                        class="btn button-default d-block d-md-block d-lg-none btn-link"><svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                        <p>Ligar</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a
                      href="https://www.google.com/maps/place/Rua Jose Dutra de Carvalho, 267 Jardim Maria Helena , Campinas, SP"><button
                        type="button" class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p class="d-block d-md-block d-lg-none">Rotas</p>
                      </button></a></div> <!---->
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><a href="https://www.procurocarreto.com.br/"><button type="button"
                        class="btn button-default btn-link"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                          <path
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                        </svg>
                        <p class="inner-text">Site</p>
                      </button></a></div>
                </div>
              </div>
              <div class="buttons-margin">
                <div>
                  <div><button type="button" class="btn button-default btn-link" v-on:click="share"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-share-fill" viewBox="0 0 16 16">
                        <path
                          d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                      <p class="inner-text">Compartilhar</p>
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div><!----><!---->
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'Oficinas',
  methods: {
    share () {
      if (navigator.share !== undefined) {
        navigator.share({
          title: 'O título da sua página',
          text: 'Achei essa empresa no sosmotoka.com.br, dê uma olhadinha! 🙂',
          url: 'https://sosmotoka.com.br/'
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error))
      }
    }
  }
}
</script>
<style lang="scss">
.g {
  display: grid;
  max-width: 70%!important;
  margin: 4px auto !important;
  grid-template-columns: repeat(5, 1fr);
  div{
    .bottom-whatsap{
        background: green;
      }
    button{
      min-width: 100%!important;
      padding:.5rem 0!important;
      border: 0;
      background: none;
      box-shadow: 0 100px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)!important;

    }
  }
  column-gap: 4px;
}
.card {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background: #fff;
  border-radius: 5px;
}

.card:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.g2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
  }
}</style>
